import React from "react";

interface Certificate {
  cert: any;
  signature: string;
}

const useCertificatePost = (
  certificate: Certificate | null,
  openerUrl: string
) => {
  const messageListener = React.useCallback(
    (event: MessageEvent): void => {
      if (event.origin !== openerUrl) return;
      if (!event.data.message) return;
      if (event.data.message === "action:close_certifier") {
        window.close();
      }
    },
    [openerUrl]
  );

  React.useEffect(() => {
    window.addEventListener("message", messageListener);
    return () => window.removeEventListener("message", messageListener);
  }, [messageListener]);

  React.useEffect(() => {
    if (certificate === null || window.opener === null) return;
    window.opener.postMessage(
      {
        certificate,
      },
      openerUrl
    );
  }, [certificate, openerUrl]);
};

export default useCertificatePost;
