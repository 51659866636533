import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./style.scss";

const ApplicationTypeSelector = (): React.ReactElement => {
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();

  const applicationParam = searchParams.get("application");
  if (applicationParam) {
    searchParams.delete("application");
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    navigation(`/application/${event.target.value}?${searchParams.toString()}`);
  };

  React.useEffect(() => {
    if (!applicationParam) return;
    navigation(`/application/${applicationParam}?${searchParams.toString()}`);
  }, [applicationParam, navigation, searchParams]);

  return (
    <Box className="selector-container">
      <FormControl fullWidth>
        <InputLabel id="application-select-label">
          Select application type
        </InputLabel>
        <Select
          id={"application-select-label"}
          onChange={handleSelectChange}
          label={"Select application type"}
        >
          <MenuItem value="web">Website</MenuItem>
          <MenuItem value="twitter-claim">
            Twitter handle claim
          </MenuItem>
          <MenuItem value="instagram-claim">
            Instagram handle claim
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default ApplicationTypeSelector;
