import "./App.css";
import { Route, Routes } from "react-router-dom";
import ApplicationTypeSelector from "./components/selector";
import { Box } from "@mui/material";
import WebStepper from "./components/steppers/web";
import TwitterStepper from "./components/steppers/twitter";
import InstagramStepper from "./components/steppers/instagram";
import InstagramAuthPopup from "./routes/InstagramAuthPopup";

function App() {
  return (
    <Box className="App">
      <Routes>
        <Route path="/" element={<ApplicationTypeSelector />} />
        <Route path="/instagram/redirect" element={<InstagramAuthPopup />} />
        <Route path="application">
          <Route path="web" element={<WebStepper />} />
          <Route path="twitter-claim" element={<TwitterStepper />} />
          <Route path="instagram-claim" element={<InstagramStepper />} />
        </Route>
        <Route path="*" element={<h1>404</h1>} />
      </Routes>
    </Box>
  );
}

export default App;
