import axios from "axios";

export type Token = string;

const getToken = async (
  starname: string,
  domain: string
): Promise<Token | null> => {
  try {
    const response = await axios.get<Token>(
      `${process.env.REACT_APP_API_URL}/generate/token/web`,
      {
        params: {
          starname,
          domain,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export interface DomainClaimCertificateResponse {
  cert: any;
  signature: string;
}

const getWebCertificate = async (
  starname: string,
  address: string,
  domain: string,
  token: string
): Promise<DomainClaimCertificateResponse | null> => {
  try {
    const response = await axios.put<DomainClaimCertificateResponse>(
      `${process.env.REACT_APP_API_URL}/claim/domain`,
      {
        starname,
        starname_address: address,
        domain,
        token,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export { getToken, getWebCertificate };
