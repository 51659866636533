import axios from "axios";

export type AuthUrl = string;

const getAuthUrl = async (): Promise<AuthUrl | null> => {
  try {
    const response = await axios.get<AuthUrl>(
      `${process.env.REACT_APP_API_URL}/generate/authurl/instagram`,
      {
        params: {
          redirect_uri: `${window.location.origin}/instagram/redirect`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export interface InstagramClaimCertificateResponse {
  cert: any;
  signature: string;
}

const getInstagramCertificate = async (
  starname: string,
  address: string,
  token: string,
  handle: string
): Promise<InstagramClaimCertificateResponse | null> => {
  try {
    const response = await axios.put<InstagramClaimCertificateResponse>(
      `${process.env.REACT_APP_API_URL}/claim/instagram_handle`,
      {
        starname,
        starname_address: address,
        token,
        handle,
        redirect_uri: `${window.location.origin}/instagram/redirect`
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export { getAuthUrl, getInstagramCertificate };
