import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { FormHelperText, IconButton, TextField } from "@mui/material";
import "./style.scss";
import {
  DomainClaimCertificateResponse,
  getWebCertificate,
  getToken,
  Token,
} from "./helper";
import { LoadingButton } from "@mui/lab";
import {
  CloudDownload,
  ContentCopy,
  FindInPage,
  NavigateNext,
} from "@mui/icons-material";
import exportJSON from "../../../utils/exportJson";
import { useSearchParams } from "react-router-dom";
import { useClipboard } from "use-clipboard-copy";
import useCertificatePost from "../../../hooks/useCertificatePost";

enum Steps {
  GET_TOKEN,
  ADD_DNS_ENTRY,
  DISPLAY_CERTIFICATE,
}

interface FetchedToken {
  checked: boolean;
  value: Token | null;
}

interface FetchedCertificate {
  checked: boolean;
  value: DomainClaimCertificateResponse | null;
}

const stepLabels = [
  "Fill required application details",
  "Update DNS entry",
  "Wooho! Certificate is ready",
];

export default function WebStepper() {
  const [searchParams] = useSearchParams();
  const clipboard = useClipboard();
  const [activeStep, setActiveStep] = React.useState<Steps>(Steps.GET_TOKEN);
  const [loading, setLoading] = React.useState<boolean>(false);

  const [starname, setStarname] = React.useState<string>(
    searchParams.get("starname") ?? ""
  );
  const [address, setAddress] = React.useState<string>(
    searchParams.get("address") ?? ""
  );
  const [domain, setDomain] = React.useState<string>(
    searchParams.get("domain") ?? ""
  );

  const [starnameError, setStarnameError] = React.useState<boolean>(false);
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [domainError, setDomainError] = React.useState<boolean>(false);

  const [token, setToken] = React.useState<FetchedToken>({
    checked: false,
    value: null,
  });

  const [certificate, setCertificate] = React.useState<FetchedCertificate>({
    checked: false,
    value: null,
  });

  useCertificatePost(
    certificate.value,
    searchParams.get("redirect_uri") ||
      (process.env.REACT_APP_OPENER_URI as string)
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkErrors = (): boolean => {
    if (activeStep === Steps.GET_TOKEN)
      return checkStarnameError() || checkAddressError() || checkDomainError();
    return false;
  };

  const checkStarnameError = (): boolean => {
    return starname.length <= 0 || starname.indexOf("*") === -1;
  };

  const checkAddressError = (): boolean => {
    return address.length <= 0 || !address.includes("star");
  };

  const checkDomainError = (): boolean => {
    return (
      domain.length <= 0 ||
      domain.includes("http://") ||
      domain.includes("https://") ||
      domain.indexOf(".") === -1
    );
  };

  const onStarnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStarname(event.target.value);
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const onDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);
  };

  const handleTokenCopy = () => {
    if (token.value) {
      clipboard.copy(token.value);
      window.alert("Token copied!");
    }
  };

  const generateToken = async (): Promise<void> => {
    setLoading(true);
    const token = await getToken(starname, domain);
    setToken({
      checked: true,
      value: token,
    });
    setLoading(false);

    if (token) handleNext();
  };

  const checkDomainDNS = async (): Promise<void> => {
    setLoading(true);
    if (!starname || !address || !domain || !token.value) return;
    const certificate = await getWebCertificate(
      starname,
      address,
      domain,
      token.value
    );
    setCertificate({
      checked: true,
      value: certificate,
    });
    setLoading(false);

    if (certificate) handleNext();
  };

  const handleDownload = (): void => {
    exportJSON(certificate.value);
    alert(
      "You can now safely close this window and add the downloaded certificate to your starname"
    );
  };

  const getStepAction = () => {
    switch (activeStep) {
      case Steps.GET_TOKEN:
        return generateToken();
      case Steps.ADD_DNS_ENTRY:
        return checkDomainDNS();
      case Steps.DISPLAY_CERTIFICATE:
        return handleDownload();
    }
  };

  const getNextActionIcon = (): React.ReactNode => {
    switch (activeStep) {
      case Steps.ADD_DNS_ENTRY:
        return <FindInPage />;
      case Steps.DISPLAY_CERTIFICATE:
        return <CloudDownload />;
      case Steps.GET_TOKEN:
        return <NavigateNext />;
    }
  };

  const getNextActionTitle = (): string => {
    switch (activeStep) {
      case Steps.GET_TOKEN:
        return loading ? "Getting token..." : "Continue";
      case Steps.ADD_DNS_ENTRY:
        return "Check now";
      case Steps.DISPLAY_CERTIFICATE:
        return "Download";
    }
  };

  const getStepContent = (): React.ReactElement => {
    switch (activeStep) {
      case Steps.GET_TOKEN:
        return (
          <Box className={"form-fields-container"}>
            <Typography variant={"subtitle2"}>
              Please enter these required details for your application
            </Typography>
            <TextField
              error={starnameError}
              label="Your starname"
              placeholder="ex: dev*iov"
              value={starname}
              onChange={onStarnameChange}
              onBlur={() => setStarnameError(checkStarnameError())}
              helperText={
                starnameError
                  ? "Valid starname looks like: dev*iov or *shop"
                  : undefined
              }
            />
            <TextField
              error={addressError}
              label="Your star address"
              placeholder="ex: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
              value={address}
              onChange={onAddressChange}
              onBlur={() => setAddressError(checkAddressError)}
              helperText={
                addressError
                  ? "Valid address looks like: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
                  : undefined
              }
            />
            <TextField
              error={domainError}
              label="Your Domain"
              placeholder="ex: starname.me"
              value={domain}
              onChange={onDomainChange}
              onBlur={() => setDomainError(checkDomainError)}
              helperText={
                domainError
                  ? "Valid domain looks like: starname.me without http or https"
                  : undefined
              }
            />
            {!loading && token.checked && token.value === null && (
              <FormHelperText error={true}>
                Failed generating token, try again
              </FormHelperText>
            )}
          </Box>
        );
      case Steps.ADD_DNS_ENTRY:
        return (
          <Box className="dns-step-container">
            {token.value ? (
              <React.Fragment>
                <Typography>
                  Please add the following token as a TXT Record entry to
                  domain's DNS.
                </Typography>
                <Typography variant="subtitle1">
                  <em>*Note:</em> For <b>name</b> field use <b>@</b> and make
                  sure you add this token to TXT record's <b>value</b> field
                </Typography>
                <Typography>
                  Refer this tutorial for adding token to{" "}
                  <a
                    href="https://telegra.ph/How-to-add-a-TXT-record-to-cloudflares-DNS-07-19"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Cloudflare
                  </a>
                </Typography>
                <Box className="token-view">
                  <span>{token.value}</span>
                  <IconButton onClick={handleTokenCopy}>
                    <ContentCopy />
                  </IconButton>
                </Box>
                <Typography>
                  * Only click <b>"Check now"</b> button after successful
                  addition of record
                </Typography>
                {!loading &&
                  certificate.checked &&
                  certificate.value === null && (
                    <FormHelperText error={true}>
                      TXT record with provided token wasn't found in DNS
                      records. DNS changes can take some time to propogate,
                      please try again after some time
                    </FormHelperText>
                  )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography gutterBottom>Invalid token</Typography>
                <Typography variant="caption" color="error">
                  Please don't proceed
                </Typography>
              </React.Fragment>
            )}
          </Box>
        );
      case Steps.DISPLAY_CERTIFICATE:
        return (
          <Box className="certificate-display-container">
            Your web certificate is ready to download
          </Box>
        );
    }
  };

  return (
    <Box className="stepper-outer-container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepLabels.map((label, idx) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent()}
              <Box sx={{ mb: 2, mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  startIcon={getNextActionIcon()}
                  loadingPosition={"start"}
                  variant="contained"
                  onClick={getStepAction}
                  disabled={checkErrors()}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {getNextActionTitle()}
                </LoadingButton>
                {activeStep < Steps.DISPLAY_CERTIFICATE && (
                  <Button
                    disabled={activeStep === Steps.GET_TOKEN}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
