import {
  CloudDownload,
  Done,
  Instagram,
  NavigateNext,
  RestartAlt,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import useCertificatePost from "../../../hooks/useCertificatePost";
import exportJSON from "../../../utils/exportJson";
import {
  AuthUrl,
  getAuthUrl,
  getInstagramCertificate,
  InstagramClaimCertificateResponse,
} from "./helper";
import "./style.scss";

enum Steps {
  GET_AUTH_URL,
  AUTH_WITH_INSTAGRAM,
  DISPLAY_CERTIFICATE,
}

interface FetchedInstagramAuthUrl {
  checked: boolean;
  value: AuthUrl | null;
}

interface FetchedCertificate {
  checked: boolean;
  value: InstagramClaimCertificateResponse | null;
}

const stepLabels = [
  "Fill required application details",
  "Authenticate with Instagram",
  "Wooho! Certificate is ready",
];

export default function InstagramStepper() {
  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = React.useState<Steps>(Steps.GET_AUTH_URL);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [authenticating, setAuthenticating] = React.useState<boolean>(false);

  const [starname, setStarname] = React.useState<string>(
    searchParams.get("starname") ?? ""
  );
  const [address, setAddress] = React.useState<string>(
    searchParams.get("address") ?? ""
  );
  const [handle, setHandle] = React.useState<string>(
    searchParams.get("handle") ?? ""
  );

  const [starnameError, setStarnameError] = React.useState<boolean>(false);
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<boolean>(false);

  const [code, setCode] = React.useState<string>("");

  const [instagramAuthUrl, setInstagramAuthUrl] =
    React.useState<FetchedInstagramAuthUrl>({
      checked: false,
      value: null,
    });

  const [certificate, setCertificate] = React.useState<FetchedCertificate>({
    checked: false,
    value: null,
  });

  useCertificatePost(
    certificate.value,
    searchParams.get("redirect_uri") ||
      (process.env.REACT_APP_OPENER_URI as string)
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkErrors = (): boolean => {
    switch (activeStep) {
      case Steps.GET_AUTH_URL:
        return (
          checkStarnameError() || checkAddressError() || checkHandleError()
        );
      case Steps.AUTH_WITH_INSTAGRAM:
        return !code;
      case Steps.DISPLAY_CERTIFICATE:
        return false;
      default:
        return false;
    }
  };

  const checkStarnameError = (): boolean => {
    return starname.length <= 0 || starname.indexOf("*") === -1;
  };

  const checkAddressError = (): boolean => {
    return address.length <= 0 || !address.includes("star");
  };

  const checkHandleError = (): boolean => {
    return handle.length <= 0;
  };

  const onStarnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStarname(event.target.value);
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandle(event.target.value);
  };

  const generateAuthUrl = async (): Promise<void> => {
    setLoading(true);
    const authUrl = await getAuthUrl();
    setInstagramAuthUrl({
      checked: true,
      value: authUrl,
    });
    setLoading(false);

    if (authUrl) handleNext();
  };

  const receiveMessage = (event: MessageEvent): void => {
    if (event.origin === window.location.origin) {
      if (event.data.errorMessage) {
        console.error(event.data.errorMessage);
      } else if (event.data.code) {
        setCode(event.data.code);
      }
    }
  };

  const authenticateWithInstagram = (): void => {
    if (!instagramAuthUrl.value) return;
    setAuthenticating(true);
    const popup = window.open(instagramAuthUrl.value);
    if (popup) {
      var pollTimer = window.setInterval(() => {
        if (popup.closed !== false) {
          // !== is required for compatibility with Opera
          window.clearInterval(pollTimer);
          setAuthenticating(false);
        }
      }, 300);
    }
    window.removeEventListener("message", receiveMessage);
    window.addEventListener("message", receiveMessage);
  };

  const generateCertificate = async (): Promise<void> => {
    if (!starname || !address || !handle || !code) return;
    setLoading(true);
    const certificate = await getInstagramCertificate(
      starname,
      address,
      code,
      handle
    );
    setCertificate({
      checked: true,
      value: certificate,
    });
    setLoading(false);

    if (certificate) handleNext();
  };

  const resetInstagramCode = (): void => {
    setCode("");
  };

  const handleDownload = (): void => {
    exportJSON(certificate.value);
    alert(
      "You can now safely close this window and add the downloaded certificate to your starname"
    );
  };

  const getStepAction = () => {
    switch (activeStep) {
      case Steps.GET_AUTH_URL:
        return generateAuthUrl();
      case Steps.AUTH_WITH_INSTAGRAM:
        return generateCertificate();
      case Steps.DISPLAY_CERTIFICATE:
        return handleDownload();
    }
  };

  const getNextActionIcon = (): React.ReactNode => {
    switch (activeStep) {
      case Steps.AUTH_WITH_INSTAGRAM:
        return <NavigateNext />;
      case Steps.DISPLAY_CERTIFICATE:
        return <CloudDownload />;
      case Steps.GET_AUTH_URL:
        return <NavigateNext />;
    }
  };

  const getNextActionTitle = (): string => {
    switch (activeStep) {
      case Steps.GET_AUTH_URL:
        return loading ? "Getting authentication url..." : "Continue";
      case Steps.AUTH_WITH_INSTAGRAM:
        return "Continue";
      case Steps.DISPLAY_CERTIFICATE:
        return "Download";
    }
  };

  const getStepContent = (): React.ReactElement => {
    switch (activeStep) {
      case Steps.GET_AUTH_URL:
        return (
          <Box className={"form-fields-container"}>
            <Typography variant={"subtitle2"}>
              Please enter these required details for your application
            </Typography>
            <TextField
              error={starnameError}
              label="Your starname"
              placeholder="ex: dev*iov"
              value={starname}
              onChange={onStarnameChange}
              onBlur={() => setStarnameError(checkStarnameError())}
              helperText={
                starnameError
                  ? "Valid starname looks like: dev*iov or *shop"
                  : undefined
              }
            />
            <TextField
              error={addressError}
              label="Your star address"
              placeholder="ex: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
              value={address}
              onChange={onAddressChange}
              onBlur={() => setAddressError(checkAddressError)}
              helperText={
                addressError
                  ? "Valid address looks like: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
                  : undefined
              }
            />
            <TextField
              error={handleError}
              label="Your instagram handle"
              placeholder="ex: starname_me"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Box
                      sx={{
                        display: "flex",
                        gap: 0.5,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Instagram /> /
                    </Box>
                  </InputAdornment>
                ),
              }}
              value={handle}
              onChange={onHandleChange}
              onBlur={() => setHandleError(checkHandleError)}
              helperText={
                handleError
                  ? "Please enter a valid instagram handle"
                  : undefined
              }
            />
            {!loading &&
              instagramAuthUrl.checked &&
              instagramAuthUrl.value === null && (
                <FormHelperText error={true}>
                  Failed generating auth url, try again
                </FormHelperText>
              )}
          </Box>
        );
      case Steps.AUTH_WITH_INSTAGRAM:
        return (
          <Box className="instagram-step-container">
            {instagramAuthUrl.value ? (
              <React.Fragment>
                <Typography>
                  You now need to authenticate with Instagram in order to
                  proceed with the application
                </Typography>
                <Typography variant="subtitle2" color={"GrayText"}>
                  <em>
                    *Note: You will be prompted to provide basic permissions for
                    us to verify your Instagram handle
                    <br />
                    Be assured, these basic permissions doesn't include any
                    special permissions and are just read only permissions to
                    verify your handle
                  </em>
                </Typography>
                <Box className="instagram-auth-actions-container">
                  <LoadingButton
                    startIcon={!!code ? <Done /> : <Instagram />}
                    loading={authenticating}
                    loadingPosition={"start"}
                    disabled={!!code}
                    onClick={authenticateWithInstagram}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    {!!code ? "Signed" : "Sign"} in with Instagram
                  </LoadingButton>
                  <IconButton disabled={!code} onClick={resetInstagramCode}>
                    <RestartAlt />
                  </IconButton>
                </Box>
                {!loading &&
                  certificate.checked &&
                  certificate.value === null && (
                    <FormHelperText error={true}>
                      Certificate can't be generated, failure verifying
                      instagram handle
                    </FormHelperText>
                  )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography gutterBottom>Invalid auth url</Typography>
                <Typography variant="caption" color="error">
                  Please don't proceed
                </Typography>
              </React.Fragment>
            )}
          </Box>
        );
      case Steps.DISPLAY_CERTIFICATE:
        return (
          <Box className="certificate-display-container">
            Your instagram-handle claim certificate is ready to download
          </Box>
        );
    }
  };

  return (
    <Box className="stepper-outer-container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepLabels.map((label, idx) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent()}
              <Box sx={{ mb: 2, mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  startIcon={getNextActionIcon()}
                  loadingPosition={"start"}
                  variant="contained"
                  onClick={getStepAction}
                  disabled={checkErrors()}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {getNextActionTitle()}
                </LoadingButton>
                {activeStep < Steps.DISPLAY_CERTIFICATE && (
                  <Button
                    disabled={activeStep === Steps.GET_AUTH_URL}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
