import React from "react";

const InstagramAuthPopup = (): null => {
  // this will redirect user back to home if user lands directly on this page
  // which shouldn't be a normal case only instagram should redirect here
  React.useEffect(() => {
    if (window.opener === null || (window.opener && window.opener === window)) {
      window.location.replace("/");
    }
  }, []);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get("error");
    if (error) {
      const errorMessage =
        params.get("error_description") || "Login failed. Please try again.";
      window.opener &&
        window.opener.postMessage(
          {
            error,
            errorMessage,
          },
          window.location.origin
        );
      // Close if user denied login
      if (error === "user_denied") {
        window.close();
      }
    }
    const code = params.get("code");
    if (code) {
      window.opener &&
        window.opener.postMessage({ code }, window.location.origin);
      window.close();
    }
  }, []);

  return null;
};

export default InstagramAuthPopup;
