import axios from "axios";

export type Token = string;

const getToken = async (
  starname: string,
  handle: string
): Promise<Token | null> => {
  try {
    const response = await axios.get<Token>(
      `${process.env.REACT_APP_API_URL}/generate/token/twitter`,
      {
        params: {
          starname,
          handle,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export interface TwitterClaimCertificateResponse {
  cert: any;
  signature: string;
}

const getTwitterCertificate = async (
  starname: string,
  address: string,
  handle: string,
): Promise<TwitterClaimCertificateResponse | null> => {
  try {
    const response = await axios.put<TwitterClaimCertificateResponse>(
      `${process.env.REACT_APP_API_URL}/claim/twitter_handle`,
      {
        starname,
        starname_address: address,
        handle,
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
  return null;
};

export { getToken, getTwitterCertificate };
