import {
  CloudDownload,
  ContentCopy,
  NavigateNext,
  Twitter,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  FormHelperText,
  IconButton,
  InputAdornment,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { useClipboard } from "use-clipboard-copy";
import useCertificatePost from "../../../hooks/useCertificatePost";
import exportJSON from "../../../utils/exportJson";
import {
  getToken,
  getTwitterCertificate,
  Token,
  TwitterClaimCertificateResponse,
} from "./helper";
import "./style.scss";

enum Steps {
  GET_PROOF_TEXT,
  TWEET_PROOF_TEXT,
  DISPLAY_CERTIFICATE,
}

interface FetchedProofText {
  checked: boolean;
  value: Token | null;
}

interface FetchedCertificate {
  checked: boolean;
  value: TwitterClaimCertificateResponse | null;
}

const stepLabels = [
  "Fill required application details",
  "Tweet proof text",
  "Wooho! Certificate is ready",
];

export default function TwitterStepper() {
  const [searchParams] = useSearchParams();
  const clipboard = useClipboard();
  const [activeStep, setActiveStep] = React.useState<Steps>(
    Steps.GET_PROOF_TEXT
  );
  const [loading, setLoading] = React.useState<boolean>(false);

  const [starname, setStarname] = React.useState<string>(
    searchParams.get("starname") ?? ""
  );
  const [address, setAddress] = React.useState<string>(
    searchParams.get("address") ?? ""
  );

  const [handle, setHandle] = React.useState<string>(
    searchParams.get("handle") ?? ""
  );

  const [starnameError, setStarnameError] = React.useState<boolean>(false);
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<boolean>(false);

  const [proofText, setProofText] = React.useState<FetchedProofText>({
    checked: false,
    value: null,
  });

  const [certificate, setCertificate] = React.useState<FetchedCertificate>({
    checked: false,
    value: null,
  });

  useCertificatePost(
    certificate.value,
    searchParams.get("redirect_uri") ||
      (process.env.REACT_APP_OPENER_URI as string)
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const checkErrors = (): boolean => {
    if (activeStep === Steps.GET_PROOF_TEXT)
      return checkStarnameError() || checkAddressError() || checkHandleError();
    return false;
  };

  const checkStarnameError = (): boolean => {
    return starname.length <= 0 || starname.indexOf("*") === -1;
  };

  const checkAddressError = (): boolean => {
    return address.length <= 0 || !address.includes("star");
  };

  const checkHandleError = (): boolean => {
    return handle.length <= 0;
  };

  const onStarnameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStarname(event.target.value);
  };

  const onAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };

  const onHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHandle(event.target.value);
  };

  const handleTokenCopy = () => {
    if (proofText.value) {
      clipboard.copy(proofText.value);
      window.alert("Proof text copied!");
    }
  };

  const generateProofText = async (): Promise<void> => {
    setLoading(true);
    const token = await getToken(starname, handle);
    setProofText({
      checked: true,
      value: token,
    });
    setLoading(false);

    if (token) handleNext();
  };

  const checkTweet = async (): Promise<void> => {
    setLoading(true);
    if (!starname || !address || !handle) return;
    const certificate = await getTwitterCertificate(starname, address, handle);
    setCertificate({
      checked: true,
      value: certificate,
    });
    setLoading(false);

    if (certificate) handleNext();
  };

  const handleDownload = (): void => {
    exportJSON(certificate.value);
    alert(
      "You can now safely close this window and add the downloaded certificate to your starname"
    );
  };

  const getStepAction = () => {
    switch (activeStep) {
      case Steps.GET_PROOF_TEXT:
        return generateProofText();
      case Steps.TWEET_PROOF_TEXT:
        return checkTweet();
      case Steps.DISPLAY_CERTIFICATE:
        return handleDownload();
    }
  };

  const getNextActionIcon = (): React.ReactNode => {
    switch (activeStep) {
      case Steps.TWEET_PROOF_TEXT:
        return <Twitter />;
      case Steps.DISPLAY_CERTIFICATE:
        return <CloudDownload />;
      case Steps.GET_PROOF_TEXT:
        return <NavigateNext />;
    }
  };

  const getNextActionTitle = (): string => {
    switch (activeStep) {
      case Steps.GET_PROOF_TEXT:
        return loading ? "Getting proof text..." : "Continue";
      case Steps.TWEET_PROOF_TEXT:
        return "Check now";
      case Steps.DISPLAY_CERTIFICATE:
        return "Download";
    }
  };

  const getStepContent = (): React.ReactElement => {
    switch (activeStep) {
      case Steps.GET_PROOF_TEXT:
        return (
          <Box className={"form-fields-container"}>
            <Typography variant={"subtitle2"}>
              Please enter these required details for your application
            </Typography>
            <TextField
              error={starnameError}
              label="Your starname"
              placeholder="ex: dev*iov"
              value={starname}
              onChange={onStarnameChange}
              onBlur={() => setStarnameError(checkStarnameError())}
              helperText={
                starnameError
                  ? "Valid starname looks like: dev*iov or *shop"
                  : undefined
              }
            />
            <TextField
              error={addressError}
              label="Your star address"
              placeholder="ex: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
              value={address}
              onChange={onAddressChange}
              onBlur={() => setAddressError(checkAddressError)}
              helperText={
                addressError
                  ? "Valid address looks like: star1aq4ts05tgkvzz24kr25jlz432sz8d8zx62ar5r"
                  : undefined
              }
            />
            <TextField
              error={handleError}
              label="Your twitter handle"
              placeholder="ex: starname_me"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Twitter /> /
                  </InputAdornment>
                ),
              }}
              value={handle}
              onChange={onHandleChange}
              onBlur={() => setHandleError(checkHandleError)}
              helperText={
                handleError ? "Please enter a valid twitter handle" : undefined
              }
            />
            {!loading && proofText.checked && proofText.value === null && (
              <FormHelperText error={true}>
                Failed generating token, try again
              </FormHelperText>
            )}
          </Box>
        );
      case Steps.TWEET_PROOF_TEXT:
        return (
          <Box className="tweet-step-container">
            {proofText.value ? (
              <React.Fragment>
                <Typography>
                  Please tweet the following text from your twitter handle (
                  <b>{handle}</b>)
                </Typography>
                <Typography variant="subtitle2" color="GrayText">
                  <em>
                    *Note: Please don't modify provided text before tweeting as
                    it will lead to verification failure
                  </em>
                </Typography>
                <Box className="token-view">
                  <span>{proofText.value}</span>
                  <IconButton onClick={handleTokenCopy}>
                    <ContentCopy />
                  </IconButton>
                </Box>
                <Typography variant="subtitle2" color="GrayText">
                  <em>
                    *Note: Only click <b>"Check now"</b> button after tweeting
                    successfully
                  </em>
                </Typography>
                {!loading && certificate.checked && certificate.value === null && (
                  <FormHelperText error={true}>
                    Tweet with provided text was not found from your handle,
                    please try again!
                    <br />
                    Make sure you tweeted provided text as it is from twitter
                    handle <b>{handle}</b>
                  </FormHelperText>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Typography gutterBottom>Invalid proof text</Typography>
                <Typography variant="caption" color="error">
                  Please don't proceed
                </Typography>
              </React.Fragment>
            )}
          </Box>
        );
      case Steps.DISPLAY_CERTIFICATE:
        return (
          <Box className="certificate-display-container">
            Your twitter-handle claim certificate is ready to download
          </Box>
        );
    }
  };

  return (
    <Box className="stepper-outer-container">
      <Stepper activeStep={activeStep} orientation="vertical">
        {stepLabels.map((label, idx) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              {getStepContent()}
              <Box sx={{ mb: 2, mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  startIcon={getNextActionIcon()}
                  loadingPosition={"start"}
                  variant="contained"
                  onClick={getStepAction}
                  disabled={checkErrors()}
                  sx={{ mt: 1, mr: 1 }}
                >
                  {getNextActionTitle()}
                </LoadingButton>
                {activeStep < Steps.DISPLAY_CERTIFICATE && (
                  <Button
                    disabled={activeStep === Steps.GET_PROOF_TEXT}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                )}
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
